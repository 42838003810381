<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-row>
        <b-col>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="search"
              placeholder="Mã giao dịch/ Nội dung"
            />
          </b-input-group>
        </b-col>

        <b-col cols="2">
          <b-form-datepicker
            v-model="dateFrom"
            local="vn"
            placeholder="Từ ngày"
            selected-variant="primary"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>

        <b-col cols="2">
          <b-form-datepicker
            v-model="dateTo"
            local="vn"
            placeholder="Đến ngày"
            selected-variant="primary"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>

        <b-col cols="2">
          <b-form-group>
            <v-select
              v-model="type"
              placeholder="Loại thanh toán"
              label="text"
              :options="typeOptions"
              :reduce="item => item.value"
            />
          </b-form-group>
        </b-col>

        <b-col cols="auto">
          <b-button
            variant="primary"
            class="mr-1"
            :disabled="isLoading"
            @click="fetchHistory(true)"
          >
            Tìm kiếm
          </b-button>
          <b-button
            variant="outline-info"
            class="mr-1 float-right"
            :disabled="isLoading"
            @click="exportExcel"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            {{ $t('Xuất Excel') }}
          </b-button>
        </b-col>
      </b-row>

      <b-table
        small
        :fields="fields"
        :items="items"
        responsive
        class="mt-2"
      >
        <!-- A virtual column -->
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(updatedAt)="data">
          <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
        </template>
        <template #cell(payGateName)="data">
          <span class="text-nowrap">{{ data.value ? 'QR Bank' : '' }}</span>
        </template>
        <template #cell(amountBeforeChange)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>
        <template #cell(amountChange)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>
        <template #cell(amountAfterChange)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>
      </b-table>

      <b-row>
        <b-col class="d-flex justify-content-md-end">
          <b-pagination
            v-model="pagination.current_page"
            :total-rows="pagination.total"
            :per-page="pagination.per_page"
            first-text="Trang đầu"
            prev-text="Trang trước"
            next-text="Trang tiếp"
            last-text="Trang cuối"
            @change="changePage"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { formatDateTimeDb } from '@/libs/timezone'
import vSelect from 'vue-select'
import { useMasterFilter, useTransactionHistory } from '@/views/order-management/useOrder'

export default {
  name: 'BalanceHistory',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BOverlay,
    vSelect,
  },
  setup() {
    const {
      merchantName,
      merchantNameOptions,
      dateFrom,
      dateTo,
      payGate,
      payGateOptions,
      fetchMerchantNameOptions,
      fetchPayGateOptions,
    } = useMasterFilter()

    const {
      fields,
      items,
      pagination,
      fetchBalanceHistory,
    } = useTransactionHistory()

    const isLoading = ref(false)
    const type = ref(null)
    const typeOptions = ref([
      { value: 'recharge', text: 'Nạp tiền' },
      { value: 'withdraw_money', text: 'Rút tiền' },
    ])
    const search = ref('')

    const fetchHistory = (isSearch = false) => {
      isLoading.value = true
      fetchBalanceHistory({
        search: search.value ? search.value : null,
        merchantName: merchantName.value ? merchantName.value : null,
        date_from: dateFrom.value ? dateFrom.value : null,
        date_to: dateTo.value ? dateTo.value : null,
        payGate: payGate.value ? payGate.value : null,
        trans_type: type.value ? type.value : null,
        page: isSearch ? 1 : pagination.value.current_page,
      }).then(response => {
        items.value = response.data
        pagination.value = response.meta.pagination
        isLoading.value = false
      })
    }

    const changePage = page => {
      pagination.value.current_page = page
      fetchHistory()
    }

    onMounted(() => {
      fetchHistory()
    })

    return {
      search,
      merchantName,
      merchantNameOptions,
      dateFrom,
      dateTo,
      payGate,
      payGateOptions,
      type,
      typeOptions,

      fields,
      items,
      pagination,
      isLoading,

      changePage,
      fetchHistory,
      fetchMerchantNameOptions,
      fetchPayGateOptions,
      formatDateTimeDb,
    }
  },
  methods: {
    exportExcel() {
      if (!this.isTimeVerify()) {
        this.$swal(this.$t('Thông báo'), this.$t('Chưa chọn thời gian hoặc khoảng thời gian lớn hơn 30 ngày'), 'warning')
        return
      }
      this.isLoading = true
      this.$http.get('pay-orders/export', {
        responseType: 'blob',
        params: {
          search: this.search ? this.search : null,
          merchantName: this.merchantName ? this.merchantName : null,
          date_from: this.dateFrom ? this.dateFrom : null,
          date_to: this.dateTo ? this.dateTo : null,
          payGate: this.payGate ? this.payGate : null,
          trans_type: this.type ? this.type : null,
        },
        headers: {
          lang: 'vi',
        },
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/xls' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `history-export-${this.dateFrom}-${this.dateTo}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false
        })
    },
    isTimeVerify() {
      if (this.dateFrom && this.dateTo) {
        const dateFrom = new Date(this.dateFrom)
        const dateTo = new Date(this.dateTo)
        const diffTime = Math.abs(dateTo - dateFrom)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays <= 30
      }
      return false
    },
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 25px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
